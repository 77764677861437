import React, { useCallback, useState } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { isCanSavaQr } from '@/utils'
import { QRCodeFunctionType } from '@/types'
import CustomTextField from '@component/Form/CustomTextField'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
  },
}))

interface Props {
  onChange?: (any) => void
  value?: any
  disabledEdit?: boolean
}

export const SHOWQR_REQUIRED = {
  ShowQrUrl: '',
}

const ShowQRPanel: React.FC<Props> = ({ onChange, value, disabledEdit = false }) => {
  const classes = useStyles()
  const [data, setData] = useState(value)

  const onInputUrl = useCallback(
    (value) => {
      const newData = Object.assign({}, data, { ShowQrUrl: value })
      Object.assign(newData, { CanSave: isCanSavaQr(QRCodeFunctionType.ShowQR, newData) })
      setData(newData)
      onChange?.(newData)
    },
    [data]
  )

  return (
    <Box className={classes.container}>
      <CustomTextField
        label="URL"
        value={data?.ShowQrUrl || ''}
        disabled={disabledEdit}
        placeholder="Enter here"
        onInputChange={onInputUrl}
        variant="outlined"
      />
    </Box>
  )
}
export default ShowQRPanel

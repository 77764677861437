import Images from '@/assets/images/CustomerPortal'
import { PanelChoice } from '@/types/panel'

export const MaterialObj = {
  '10pt C1S': {
    default: '11 × 8.5',
    option: [
      '4.25 × 5.5',
      '4.25 × 11',
      '5.5 × 8.5',
      '6 × 4',
      '7 × 5',
      '8.5 × 3.5',
      '9 × 4',
      '9 × 6',
      '9 × 12',
      '11 × 8.5',
      '11 × 17',
      '14 × 8.5',
    ],
  },
  '80# Gloss': {
    default: '8.5 × 11',
    option: ['8.5 × 11'],
  },
  Foamboard: {
    default: '8 × 10',
    option: ['5 × 8', '8 × 10', '18 × 24'],
  },
  'Vinyl Cling-1 Side': {
    default: '18 × 24',
    option: [
      '3 × 3',
      '3 × 5',
      '4 × 4',
      '4 × 6',
      '5 × 5',
      '7 × 5',
      '8 × 8',
      '11 × 8.5',
      '12 × 12',
      '20 × 16',
      '18 × 24',
      '24 × 24',
      '30 × 20',
      '24 × 36',
      '40 × 28',
      '53 × 30',
      '53 × 60',
    ],
  },
  'Vinyl Cling-2 Sides': {
    default: '18 × 24',
    option: [
      '3 × 3',
      '3 × 5',
      '4 × 4',
      '4 × 6',
      '5 × 5',
      '7 × 5',
      '8 × 8',
      '11 × 8.5',
      '12 × 12',
      '20 × 16',
      '18 × 24',
      '24 × 24',
      '30 × 20',
      '24 × 36',
      '40 × 28',
      '53 × 30',
      '53 × 60',
    ],
  },
  '20 mil Styrene': {
    default: '10 × 8',
    option: ['10 × 8', '20 × 16', '18 × 24', '30 × 20', '28 × 22', '24 × 36'],
  },
  '.25" Acrylic': {
    default: '3 × 5',
    option: ['3 × 5', '5 × 8', '8 × 10'],
  },
  '3mm Sintra': {
    default: '5 × 8',
    option: [
      '5 × 8',
      '10 × 8',
      '11 × 8.5',
      '12 × 12',
      '14 × 20',
      '17 × 11',
      '18 × 18',
      '20 × 16',
      '18 × 24',
      '24 × 16',
      '24 × 24',
      '28 × 22',
      '30 × 30',
      '30 × 24',
      '32 × 24',
      '24 × 36',
    ],
  },
  '10pt Doorhanger': {
    default: '8.5 × 3.5',
    option: ['8.5 × 3.5', '8.5 × 5.25', '11 × 4', '11 × 8.5', '14 × 4', '14 × 8.5', '18.5 × 16.25', '27 × 6.25'],
  },
  'Retractable Banner': {
    default: '79 × 33.5',
    option: ['79 × 23.5', '79 × 33.5', '79 × 47'],
  },
  'Bent Acrylic': {
    default: '3 × 5',
    option: ['3 × 5', '4 × 6'],
  },
  'Bent Dibond Aluminum': {
    default: '3 × 5',
    option: ['3 × 5', '4 × 6'],
  },
  NA: {
    default: 'NA',
    option: ['NA'],
  },
}
export enum QRLimit {
  QrHub = 'QrHub',
  QrCode = 'QrCode',
  Round = 'Round',
  Inspection = 'Inspection',
}
export const MaterialOptions = Object.keys(MaterialObj)
export type MaterialKey = keyof typeof MaterialObj

export const EquipmentType = [
  {
    DisplayText: {
      en: 'Air Conditioner',
    },
    Name: 'AirConditioner',
  },
  {
    DisplayText: {
      en: 'Generator',
    },
    Name: 'Generator',
  },
  {
    DisplayText: {
      en: 'Fire Pump',
    },
    Name: 'FirePump',
  },
  {
    DisplayText: {
      en: 'Fan',
    },
    Name: 'Fan',
  },
  {
    DisplayText: {
      en: 'Water pump',
    },
    Name: 'WaterPump',
  },
  {
    DisplayText: {
      en: 'Dry Valve',
    },
    Name: 'DryValve',
  },
  {
    DisplayText: {
      en: 'HVAC',
    },
    Name: 'HVAC',
  },
  {
    DisplayText: {
      en: 'Other Pumps',
    },
    Name: 'OtherPumps',
  },
  {
    DisplayText: {
      en: 'Other Tanks',
    },
    Name: 'OtherTanks',
  },
  {
    DisplayText: {
      en: 'Air Compressor',
    },
    Name: 'AirCompressor',
  },
  {
    DisplayText: {
      en: 'Boilers',
    },
    Name: 'Boilers',
  },
  {
    DisplayText: {
      en: 'Other',
    },
    Name: 'Other',
  },
  {
    DisplayText: {
      en: 'Condensor',
    },
    Name: 'Condensor',
  },
  {
    DisplayText: {
      en: 'Meter',
    },
    Name: 'Meter',
  },
  {
    DisplayText: {
      en: 'Heaters',
    },
    Name: 'Heaters',
  },
  {
    DisplayText: {
      en: 'Fire Panel',
    },
    Name: 'FirePanel',
  },
  {
    DisplayText: {
      en: 'Other Valves',
    },
    Name: 'OtherValves',
  },
]

export const ROUND_EQUIPMENT_TYPE = [
  ...EquipmentType,
  {
    DisplayText: {
      en: 'AED',
    },
    Name: 'AED',
  },
  {
    DisplayText: {
      en: 'Fire Extinguisher',
    },
    Name: 'FireExtinguisher',
  },
].sort((item, item1) => item.Name.localeCompare(item1.Name))

export const INSPECTION_TYPE = [
  {
    DisplayText: {
      en: 'Backflow Valve',
    },
    Name: 'BackflowValve',
  },
  {
    DisplayText: {
      en: 'Elevator',
    },
    Name: 'Elevator',
  },
  {
    DisplayText: {
      en: 'Fire Extinguisher',
    },
    Name: 'FireExtinguisher',
  },
  {
    DisplayText: {
      en: 'Generator',
    },
    Name: 'Generator',
  },
  {
    DisplayText: {
      en: 'Roof',
    },
    Name: 'Roof',
  },
  {
    DisplayText: {
      en: 'HVAC',
    },
    Name: 'HVAC',
  },
  {
    DisplayText: {
      en: 'Mold',
    },
    Name: 'Mold',
  },
  {
    DisplayText: {
      en: 'Plumbing',
    },
    Name: 'Plumbing',
  },
].sort((item, item1) => item.Name.localeCompare(item1.Name))

export const ErrorRuleOption = [
  {
    DisplayText: {
      en: 'is greater than',
    },
    Name: 'IsGreaterThan',
  },
  {
    DisplayText: {
      en: 'is greater than or equal to',
    },
    Name: 'IsGreaterThanOrEqualTo',
  },
  {
    DisplayText: {
      en: 'is less than',
    },
    Name: 'IsLessThan',
  },
  {
    DisplayText: {
      en: 'is less than or equal to',
    },
    Name: 'IsLessThanOrEqualTo',
  },
  {
    DisplayText: {
      en: 'is equal to',
    },
    Name: 'IsEqualTo',
  },
  {
    DisplayText: {
      en: 'is not equal to',
    },
    Name: 'IsNotEqualTo',
  },
  {
    DisplayText: {
      en: 'inside the tolerance range',
    },
    Name: 'InsideRange',
  },
  {
    DisplayText: {
      en: 'outside the tolerance range',
    },
    Name: 'OutsideRange',
  },
]

export const QuestionType = [
  {
    DisplayText: {
      en: 'Dropdown',
    },
    Name: 'DropDown',
  },
  {
    DisplayText: {
      en: 'Checkbox',
    },
    Name: 'BooleanChoice',
  },
  {
    DisplayText: {
      en: 'Numeric (UoM)',
    },
    Name: 'Number',
  },
  {
    DisplayText: {
      en: 'Multiple Choice',
    },
    Name: 'CheckBox',
  },
  {
    DisplayText: {
      en: 'Text Field',
    },
    Name: 'Text',
  },
  {
    DisplayText: {
      en: 'Radio Button',
    },
    Name: 'SingleChoice',
  },
  {
    DisplayText: {
      en: 'Photo Upload',
    },
    Name: 'TakePhoto',
  },
  {
    DisplayText: {
      en: 'Yes/No',
    },
    Name: 'BoolSingleChoice',
  },
  {
    DisplayText: {
      en: 'Date',
    },
    Name: 'Date',
  },
  {
    DisplayText: {
      en: 'ON/OFF',
    },
    Name: 'BoolOnOff',
  },
].sort((a, b) => a.DisplayText.en.localeCompare(b.DisplayText.en))

export enum EquipmentFrom {
  Library = 'Library',
  Engineering = 'Engineering',
  AEDInspection = 'AEDInspection',
  FireExtinguisherInspection = 'FireExtinguisherInspection',
  SecurityPatrol = 'SecurityPatrol',
  Cleaning = 'Cleaning',
  Inspection = 'Inspection',
  Installation = 'Installation',
  Maintenance = 'Maintenance',
  PreventativeMaintenance = 'PreventativeMaintenance',
  Flexible = 'Flexible',
}

export const customerQRList = [
  {
    header: 'Amenities Instructions',
    desc: 'Direct users to relevant information at an amenity location. As your amenities and instructions change, you never have to update signage because the content behind our QR can be dynamically updated whenever needed',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Amenitiy-Hub.png?time=1681321592',
    qrName: 'AmenitiesInstructions',
    carouselList: [
      Images.AmenitiesInstructions01,
      Images.AmenitiesInstructions02,
      Images.AmenitiesInstructions03,
      Images.AmenitiesInstructions04,
      Images.AmenitiesInstructions05,
      Images.AmenitiesInstructions06,
      Images.AmenitiesInstructions07,
      Images.AmenitiesInstructions08,
    ],
  },
  // {
  //   header: 'Announcements',
  //   desc: 'Extend the amount of information available to any building announcement by adding a QR to access more information about the announcement',
  //   img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Announcements.png?time=1680840793',
  //   qrName: 'Announcements',
  // },
  {
    header: 'Conference Room Hub',
    desc: "Consolidate all instructions in a Conference into one place that doesn't require reading multiple signs and writing information down",
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Conference-Hub.png?time=1681321592',
    qrName: 'ConferenceRoomInstructions',
    carouselList: [
      Images.ConferenceRoomInstructions01,
      Images.ConferenceRoomInstructions02,
      Images.ConferenceRoomInstructions03,
      Images.ConferenceRoomInstructions04,
      Images.ConferenceRoomInstructions05,
      Images.ConferenceRoomInstructions06,
      Images.ConferenceRoomInstructions07,
      Images.ConferenceRoomInstructions08,
    ],
  },
  {
    header: 'Download App',
    desc: 'Direct visitors to your building app personalized to their mobile device’s app store. ',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/download-app.png?time=1681321592',
    qrName: 'DownloadApp',
    carouselList: [Images.DownloadApp01],
  },
  {
    header: 'FAQs',
    desc: "Save valuable time of your building staff and increase accessibility to frequently asked questions by adding FAQ QR's throughout the building",
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfwliV-wL-ABNDdFRyp3N0KYzUQL7zI7eHTqZAl7WG&s',
    qrName: 'FAQs',
    carouselList: [Images.FAQs01],
  },
  {
    header: 'HTML',
    desc: "3x engagement by directly linking to any customized page as opposed to making people type in/write down URL's",
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Apply-Now.png?time=1680840793',
    qrName: 'Html',
    carouselList: [Images.Html01, Images.Html02],
  },
  {
    header: 'Join Wifi',
    qrName: 'JoinWifi',
    desc: 'Link tenants directly to your Local Area Networks for easy access to the internet. ',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/wifi.png?time=1681321592',
    carouselList: [Images.JoinWifi01],
  },
  {
    header: 'PDF',
    qrName: 'PDF',
    desc: 'Digitize the PDF experience by either adding a QR to any PDF, or putting a QR anywhere that opens up any PDF',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2023/02/Digitized-PDF.png?time=1688039049',
    carouselList: [Images.PDF01, Images.PDF02, Images.PDF03],
  },
  {
    header: 'Room Rental',
    qrName: 'RoomRental',
    desc: 'Increase rental revenue by allowing instant ability to sign-up or collect information when people are interested in renting rooms in the building',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Residential-Rental-Hub.png?time=1681321592',
    carouselList: [Images.RoomRental01, Images.RoomRental01],
  },
  {
    header: 'Static URL',
    qrName: 'StaticUrl',
    desc: "3x engagement by directly linking to any page as opposed to making people type in/write down URL's",
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Apply-Now.png?time=1680840793',
    carouselList: [Images.StaticUrl01],
  },
  {
    header: 'Uploaded File',
    qrName: 'EmbeddedFile',
    desc: '3x engagement by directly linking to any file in any format as opposed to making people type in/write down info on a static sign',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Apply-Now.png?time=1680840793',
    carouselList: [Images.EmbeddedFile01],
  },
  {
    header: 'Watch Video',
    qrName: 'WatchVideo',
    desc: 'Trigger videos to play on a mobile device immediately from a point-and-scan opposed to searching for a video on a platform',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Watch-Video.png?time=1681321592',
    carouselList: [Images.WatchVideo01],
  },
  {
    header: 'Wifi Instructions',
    qrName: 'WifiInstructions',
    desc: 'Direct users to WiFi instructions with one point-and-scan, opposed to writing information down',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/wifi.png?time=1681321592',
    carouselList: [Images.WifiInstructions01],
  },
  {
    header: 'Collateral',
    qrName: 'Collateral',
    desc: 'Enhance marketing material by adding a QR that captures leads, allows action, or provides more information',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Apply-Now.png?time=1680840793',
    carouselList: [Images.Collateral01],
  },
  {
    header: 'Contact',
    qrName: 'Contact',
    desc: 'Seamlessly connect anyone to the front desk, staff, agent, or person in need immediately via point-and-scan opposed to writing information down or requiring an app download',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2023/02/Contact-Us-1.png?time=1681321592',
    carouselList: [Images.Contact01],
  },
  {
    header: 'Event',
    qrName: 'CheckIn',
    desc: 'Check in: Invite tenants to building events which also allows attendants to check in and provide feedback of the event. ',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2023/02/Event-Feedback.png?time=1681321592',
    carouselList: [Images.CheckIn01],
  },
  {
    header: 'Job Application',
    qrName: 'JobApplication',
    desc: 'Make it easier for anyone to apply for your open positions in one point-and-scan opposed to writing down information and following up later. Immediately engage and fill open positions faster.',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Apply-Now.png?time=1681321592',
    carouselList: [Images.JobApplication01],
  },
  {
    header: 'Lead Capture',
    qrName: 'LeadCapture',
    desc: 'Capture leads with a custom form. ',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2023/02/Lead-Capture.png?time=1681321592',
    carouselList: [Images.LeadCapture01],
  },
  // {
  //   header: 'Lease Now',
  //   qrName: 'LeaseNow',
  //   desc: 'Capture leads and drive revenue by allowing anyone to immediately submit their information or gather information about available leases',
  //   img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Retail-Vacancy-Hub.png?time=1681321592',
  // },
  {
    header: 'Social Media',
    qrName: 'SocialMedia',
    desc: 'Increase followers by putting all social media handles one-click away on a single landing page. Erase the friction of writing down/typing out individual @ handles by sending followers directly to your social media pages',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Social-Media-Hub.png?time=1681321592',
    carouselList: [Images.SocialMedia01, Images.SocialMedia02, Images.SocialMedia03],
  },
  {
    header: 'Vacancy Hub',
    qrName: 'VacancyHub',
    desc: "Collect leads by sending interested tenants to one page that allows them to request a tour, watch marketing videos, see collateral, submit information, and more. Our SmartHubs allow one QR to point to multiple landing pages or forms. Don't make it difficult to engage in-the-moment with those interested in your vacant spaces.",
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Apply-Now.png?time=1680840793',
    carouselList: [Images.VacancyHub01, Images.VacancyHub02, Images.VacancyHub03],
  },
  {
    header: 'Conference Room Hub',
    qrName: 'ConferenceRoomHub',
    desc: "Consolidate all instructions in a Conference into one place that doesn't require reading multiple signs and writing information down",
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Conference-Hub.png?time=1681321592',
    carouselList: [
      Images.ConferenceRoomHub01,
      Images.ConferenceRoomHub02,
      Images.ConferenceRoomHub03,
      Images.ConferenceRoomHub04,
      Images.ConferenceRoomHub05,
      Images.ConferenceRoomHub06,
      Images.ConferenceRoomHub07,
      Images.ConferenceRoomHub08,
    ],
    disabled: true,
  },
  {
    header: 'Tenant Hub',
    qrName: 'TenantHub',
    desc: 'Provide instant access to information, content, and communication channels in the unit with one point & scan opposed to forcing log-in to an app or platform',
    img: 'https://s0f40c.p3cdn1.secureserver.net/wp-content/uploads/2022/11/Tenant-Hub.png?time=1688039049',
    carouselList: [
      Images.TenantHub01,
      Images.TenantHub02,
      Images.TenantHub03,
      Images.TenantHub04,
      Images.TenantHub05,
      Images.TenantHub06,
    ],
    disabled: true,
  },
]

export const MethodOptions: Array<PanelChoice> = [
  { label: 'Text', value: 'Text' },
  { label: 'Email', value: 'Email' },
]

export const TAG_TYPE = [
  {
    label: 'QR Only',
    value: 'QROnly',
  },
  {
    label: 'QR + NFC',
    value: 'QRAndNFC',
  },
  {
    label: 'NFC Only',
    value: 'NFCOnly',
  },
]
